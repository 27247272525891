"use strict";
var portal = angular.module("portal", [
    "ngRoute",
    "oc.lazyLoad",
    "angular-growl",
    "ui.bootstrap",
    "angular-loading-bar",
    "ui.select",
    "ngSanitize",
    "mgo-angular-wizard",
    "chart.js",
    "angular-jwt",
    "angularMoment",
    "monospaced.qrcode",
    "angularSpinner",
    "angular-uuid",
    "ngIntlTelInput",
    "nvd3",
    "as.sortable",
    "ngQuill",
    "ng.jsoneditor",
]);

var roles = {
    systemAdmin: 1,
    systemUser: 2,
    partnerUser: 3,
    clientAdmin: 4,
    clientUser: 5,
    partnerSupervisor: 9,

    systemAnyUser: [1, 2],
    partnerAnyUser: [3, 9],
    clientAnyUser: [4, 5],
};
portal.constant("ROLES", roles);

var enums = {
    ImportHistoryActionType: {
        clientImportValidate: 1,
        clientImport: 2,

        partnerTransactionSummary: 15,
        clientTransactionSummary: 16,
    },

    ImportHistoryStatus: {
        created: 1,
        importing: 2,
        imported: 3,
        failed: 4,
    },

    DocumentType: {
        eula: 1,
    },

    UserEulaActionType: {
        accepted: 1,
        declined: 2,
    },

    AuditSource: {
        externalApi: 1,
        portal: 2,
    },

    AuditSeverity: {
        debug: 0,
        info: 5,
        warn: 10,
        error: 15,
    },

    AuditAction: {
        default: 1,
        partnerCreate: 2,
        partnerUpdate: 3,
        partnerDelete: 4,

        userLogin: 10,
        userLoginOtp: 11,
        userLoginOtpRecovery: 12,
        userSamlLogin: 13,

        userLogout: 15,

        userLoginFailure: 16,
        userLoginOtpFailure: 17,
        userLoginRecoveryFailure: 18,
        userSamlLoginFailure: 19,

        brandingCreate: 80,
        brandingUpdate: 81,
        brandingDelete: 82,

        clientImport: 100,

        partnerValidate: 200,
        templateValidate: 201,
        tokenizationWrite: 202,
        tokenizationRead: 203,
        tokenizationHealthcheck: 204,

        templateCreate: 210,
        templateUpdate: 211,

        injectKeyCreate: 220,
        injectKeyUpdate: 221,

        gatewayTemplateCreate: 230,
        gatewayTemplateUpdate: 231,
        gatewayTemplateDelete: 232,
    },

    MaintenanceDisplayMode: {
        minimized: "minimized",
        full: "full",
    },

    TransactionType: {
        partnerValidate: 1,
        templateValidate: 2,
        healthcheck: 3,
        tokenizeWrite: 4,
        tokenizeRead: 5,
        threeDs: 6,
    },

    TemplateAccessMode: {
        unrestricted: "unrestricted",
        restricted: "restricted",
        specific: "specific",
    },

    TemplateType: {
        userDefined: "userDefined",
        payConexTokenPayments: "payConexTokenPayments",
        payConexTokenization: "payConexTokenization",
    },

    InjectKeyStatus: {
        available: "available",
    },

    userExtendedSettingType: {
        notifications: "Notifications",
    },
};
portal.constant("$enums", enums);

// todo [as] read this option from server side, rename it to something more generic
var applicationOptions = {
    batchActionMaxSize: 50000, // also defined on the server side
    defaultWaitTimeout: 1000,
    clientCreationMaxBatchSize: 500,
};
portal.constant("ApplicationOptions", applicationOptions);

portal.constant("MENU", {
    menus: [
        {
            title: "Manage",
            key: "manage",
            _url: "/manage",
            roles: [roles.systemAdmin, roles.systemUser, roles.partnerUser, roles.partnerSupervisor, roles.clientAdmin],
            claim: "BUSINESS_ACTIVITY",
            leaves: [
                {
                    url: "/manage/users",
                    title: "Users",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                    ],
                },
                {
                    url: "/manage/partners",
                    title: "Partners",
                    roles: [roles.systemAdmin, roles.systemUser, roles.partnerUser, roles.partnerSupervisor],
                },
                {
                    url: "/manage/clients",
                    title: "Clients",
                    roles: [roles.systemAdmin, roles.systemUser, roles.partnerUser, roles.partnerSupervisor],
                },
                {
                    url: "/manage/injectKeys",
                    title: "Inject Keys",
                    roles: [roles.systemAdmin],
                },
                {
                    url: "/manage/templates",
                    title: "Templates",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                    ],
                },
                {
                    url: "/manage/template-sharing",
                    title: "Template Sharing",
                    roles: [roles.systemAdmin],
                },
                {
                    url: "/manage/emails",
                    title: "Email Templates",
                    roles: [roles.systemAdmin, roles.systemUser],
                },
                {
                    url: "/manage/proxy-configurations",
                    title: "Proxy Configurations",
                    roles: [roles.systemAdmin],
                },
                {
                    url: "/manage/branding",
                    title: "Branding",
                    roles: [roles.systemAdmin, roles.systemUser],
                },
                {
                    url: "/manage/documentstore/eula",
                    title: "Eula Documents",
                    roles: [roles.systemAdmin],
                },
                {
                    url: "/manage/systemConfig",
                    title: "System Configuration",
                    roles: [roles.systemAdmin, roles.systemUser],
                },
                {
                    url: "/manage/eventLogging",
                    title: "Event Logging",
                    roles: [roles.systemAdmin, roles.systemUser, roles.partnerSupervisor],
                },
                {
                    url: "/manage/systemnotifications",
                    title: "System Notifications",
                },
                {
                    url: "/manage/notificationManagement",
                    title: "Notification Management",
                    roles: [roles.systemAdmin],
                },
                {
                    url: "/saml/samlconfiguration",
                    title: "SAML Configuration",
                    roles: [roles.systemAdmin, roles.systemUser],
                },
            ],
        },
        {
            title: "Transactions",
            url: "/transactions",
            roles: [
                roles.systemAdmin,
                roles.systemUser,
                roles.partnerUser,
                roles.partnerSupervisor,
                roles.clientAdmin,
                roles.clientUser,
            ],
            claim: "BUSINESS_ACTIVITY",
        },
        {
            title: "Proxy Logs",
            url: "/proxy-logs",
            roles: [
                roles.systemAdmin,
                roles.systemUser,
                roles.partnerUser,
                roles.partnerSupervisor,
                roles.clientAdmin,
                roles.clientUser,
            ],
            claim: "BUSINESS_ACTIVITY",
            show: function (core) {
                return Promise.resolve(core.ui.appConfig.features.gateway);
            },
        },
        {
            title: "Virtual Terminal",
            url: "/virtualTerminal",
            roles: [
                roles.systemAdmin,
                roles.systemUser,
                roles.partnerUser,
                roles.partnerSupervisor,
                roles.clientAdmin,
                roles.clientUser,
            ],
            claim: "BUSINESS_ACTIVITY",
            show: function (core) {
                return core.virtualTerminal.isAvailable();
            },
        },
        {
            title: "Reports",
            key: "reports",
            _url: "/reports",
            claim: "BUSINESS_ACTIVITY",
            leaves: [
                {
                    url: "/reports/partnerSummary",
                    title: "Partner Summary",
                    roles: [roles.systemAdmin, roles.systemUser, roles.partnerSupervisor, roles.partnerUser],
                },
                {
                    url: "/reports/clientSummary",
                    title: "Client Summary",
                    roles: [roles.systemAdmin, roles.systemUser, roles.partnerSupervisor, roles.partnerUser],
                },
                {
                    url: "/reports/transaction-summary-partner",
                    title: "Partner Transaction Summary",
                    roles: [roles.systemAdmin, roles.systemUser, roles.partnerUser, roles.partnerSupervisor],
                },
                {
                    url: "/reports/transaction-summary-client",
                    title: "Client Transaction Summary",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
                {
                    url: "/reports/eula",
                    title: "Eula Summary",
                    roles: [roles.systemAdmin],
                },
                {
                    url: "/reports/userReport",
                    title: "User Report",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
                {
                    url: "/reports/tokenizationTotals",
                    title: "Tokenization Totals",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
                {
                    url: "/reports/tokenizationUnclaimed",
                    title: "Tokenization Unclaimed",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
                {
                    url: "/reports/tokenizationReferrer",
                    title: "Tokenization Referrers",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },

                {
                    url: "/reports/3dsDetails",
                    title: "3DS Details Report",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
                {
                    url: "/reports/3dsPartnerSummary",
                    title: "Partner 3DS Summary",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
                {
                    url: "/reports/3dsClientSummary",
                    title: "Client 3DS Summary",
                    roles: [
                        roles.systemAdmin,
                        roles.systemUser,
                        roles.partnerUser,
                        roles.partnerSupervisor,
                        roles.clientAdmin,
                        roles.clientUser,
                    ],
                },
            ],
        },
        {
            title: "Documentation",
            url: "/documentation",
            claim: "BUSINESS_ACTIVITY",
        },
        {
            title: "Contact Support",
            url: "/contact",
            claim: "BUSINESS_ACTIVITY",
        },
        {
            title: "Personal settings",
            key: "settings",
            _url: "/settings",
            hideInTopMenu: true,
            leaves: [
                {
                    title: "Profile",
                    url: "/settings/profile",
                    claim: "CREDENTIALS_ACTIVITY",
                },
                {
                    title: "Account",
                    url: "/settings/account",
                    claim: "CREDENTIALS_ACTIVITY",
                },
                {
                    title: "Security",
                    url: "/settings/security",
                    claim: "CREDENTIALS_ACTIVITY",
                },
            ],
        },
    ],
});
